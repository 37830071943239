import { confetti } from '@tsparticles/confetti';
import Mailus from 'files/mailus';
import Settings from 'files/settings';
import Thunder from 'files/thunder';
import { useEffect, useState } from 'react';
import About from './about';
import Accordion from './accordion';
// import Carousel from './carousel';
import ClickSpark from './clickSpark';
import Employee from './employee';
import Footer from './footer';
import OnePlatform from './oneplatform';
import PricingSection from './price';
import styles from './website.module.scss';
import WhyChoose from './whyChoose';
import Customers from './customers';


const WebSite = () => {
  const Messages = ["Task Planner", "Your team", "Files", "API", "Invoices", "CI/CD"];
  const [index, setIndex] = useState(0);
  const [fade, setFade] = useState(true);
  const [progressWidth, setProgressWidth] = useState();

  useEffect(() => {
    const hasRun = localStorage.getItem('hasRun');
    if (!hasRun) {
      confetti({
        angle: randomInRange(55, 125),
        spread: randomInRange(90, 70),
        particleCount: randomInRange(150, 100),
        origin: { y: 0.6 }
      });

      localStorage.setItem('hasRun', 'true');
    }

  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const winScroll =
        document.body.scrollTop || document.documentElement.scrollTop;
      const height =
        document.documentElement.scrollHeight -
        document.documentElement.clientHeight;
      const scrolled = (winScroll / height) * 100;
      setProgressWidth(scrolled);

    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
    
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setFade(false);
      setTimeout(() => {
        setIndex((prevIndex) => (prevIndex + 1) % Messages.length);
        setFade(true);
      }, 1000);
    }, 3000);

    return () => clearInterval(interval);
  }, [Messages.length]);




  function randomInRange(min, max) {
    return Math.random() * (max - min) + min;
  }

  return (
    <div>
      <ClickSpark />
      <div className="bg-white">
       

        <div className={`relative isolate px-6 pt-4 lg:px-8`} id="home">
         
          <div
            aria-hidden="true"
            className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
          >
            <div/>
          </div>
         
          
          <div className={styles.ballsWrapper}>
            <div className={styles.colorBallTwo}>
              <div className={styles.ball}>
              </div>
            </div>
            <div className={`${styles.colorBallThree}`}>
              <div className={styles.ball}>
              </div>
            </div>
            <div className={`${styles.colorBallOne}`}>
              <div className={styles.ball}>
              </div>
            </div>
            <div className={`${styles.colorBallFour}`}>
              <div className={styles.ball}>
              </div>
            </div>
            <div className={`${styles.colorBallFive}`}>
              <div className={styles.ball}>
              </div>
            </div>
          </div>
          <div className={`${styles.bgLines}  mx-auto max-w-4xl py-12 sm:py-28 lg:py-13`}>
            <div className="text-center">
              <h1 className={`text-4xl font-bold ${styles.titleColor} ${styles.mainCenterTitle} mb-7 tracking-tight sm:text-6xl`} data-aos="fade-up"  data-aos-duration="800">
            Start a new experience in
              </h1>
              <h1 className={`${styles.mainCenterTitle} text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl`} data-aos="fade-up"  data-aos-duration="800"  data-aos-delay="50">
            collaborating with <span className={`${styles.textRainbowAnimation} ${styles.dynamicText} ${fade ? styles.fadeIn : styles.fadeOut}`}>{Messages[index]}</span>
              </h1>
             
              <p className={`${styles.subCenterTitle} mt-6 text-lg leading-8 text-gray-600`} data-aos="fade-up"  data-aos-duration="800"  data-aos-delay="100">
            Kwapio is a collaborative platform gathering human centered-methodologies, Everything will be organized in one place, your team will be working together; you’ll be on top of things, and a sense of calm will set in.
              </p>
              <div className="mt-10 flex flex-col lg:flex-row gap-5 items-center hover:drop-shadow-lg justify-center gap-x-6" data-aos="fade-up"  data-aos-duration="800"  data-aos-delay="150">
                <span className={`cursor-pointer rounded-md inline-flex ${styles.primaryColorBtn} px-5 py-3.5 text-sm font-semibold text-white shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600`} onClick={()=>window.location.href = "https://app.kwapio.com/account/create-account"}>
                  <Thunder className={styles.thunderIcon} />
                  <span className='ms-2'> Get started</span>
                </span>
                <a
                  href="mailto:kamal@qubernet.com"
                  target="_blank"
                  rel="noreferrer"
                  className={`bg-white rounded-md hover:shadow-md ml-2 inline-flex ${styles.btnTwo} border-black border-2 px-5 py-3.5 text-sm font-semibold shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600`}
                >
                  <Settings className={styles.spin} />
                  <span className="ms-2">Request Demo</span>
                </a>
                
              </div>
              <div className='brands'>
                <Customers/>
              </div>
            </div>
            <div className={styles.bgImage}></div>
           
          </div>
          <div className={`${styles.textScroll}`}>
            <svg width="100%" height="120%">
              <text x="10" y="145">Kwapio Kwapio Kwapio Kwapio Kwapio Kwapio Kwapio Kwapio Kwapio Kwapio Kwapio Kwapio</text>
            </svg>
          </div>
        </div>


        <div>
          {/* <SectionTwo progressWidth={progressWidth}/> */}
          <OnePlatform/>
        </div>
        <div className={styles.whyChooseWrapper}>
          <WhyChoose/>
        </div>

        {/* <div>
          <Carousel progressWidth={progressWidth}/>
        </div> */}

        <div id='about'>
          <About/>
        </div>

        <div>
          <Accordion progressWidth={progressWidth}/>
        </div>

        <div>
          <PricingSection/>
        </div>
      
        <div className={styles.employeeWrapper}>

          <h1 className={` text-center text-4xl font-bold  ${styles.mainCenterTitle} tracking-tight sm:text-6xl`} >
         
          </h1>
          <h2 className={`${styles.titleColor} text-2xl text-gray-50 font-bold md:text-4xl text-center md:mb-5`} data-aos="fade-up"  data-aos-duration="800">  Meet Our Team</h2>
          <p className="max-w-3xl mx-auto mt-4 text-xl text-center ">Our team is the backbone of our success without them nothing would work out.</p>
          <Employee/>
        </div>

        
        <div id='contact'>
          <div className="py-4 bg-white">
            <div className="text-center">
        
              <h3 className="text-3xl sm:text-3xl leading-normal font-extrabold tracking-tight text-gray-900">
        Still have questions?
              </h3>
              <p className="mt-4 text-sm  sm:text-lg leading-7 text-gray-500 font-regular">
        Please describe your case to receive the most accurate advice
              </p>
              <div className="flex justify-center mt-4">
                <a href='mailto:kamal@qubernet.com' target="_blank" rel="noreferrer" className={`cursor-pointer bg rounded-md inline-flex bg-gray-950 px-5 py-2 text-sm font-semibold text-white shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600`}>
                  <Mailus  width={20} height={20}/>
                  <span className='ms-2'> Contact us</span>
                </a>
              </div>
            </div>
          </div>
        </div>
        
        <div>
          <Footer />
        </div>
        
      

      </div>
      {
        progressWidth > 30 &&  <div className="fixed bottom-0 right-0 p-4">
          <button className="bg bg-kogreen-dark text-white rounded-full w-10 h-10 flex items-center justify-center" onClick={()=>window.scrollTo(0, 0)}>
            <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" className="iconify iconify--solar mnl__icon__root MuiBox-root css-17b32sj" width="1em" height="1em" viewBox="0 0 24 24"><path fill="currentColor" d="M5 17.75a.75.75 0 0 1-.488-1.32l7-6a.75.75 0 0 1 .976 0l7 6A.75.75 0 0 1 19 17.75z" opacity=".5"></path><path fill="currentColor" fillRule="evenodd" d="M4.43 13.488a.75.75 0 0 0 1.058.081L12 7.988l6.512 5.581a.75.75 0 1 0 .976-1.138l-7-6a.75.75 0 0 0-.976 0l-7 6a.75.75 0 0 0-.081 1.057" clipRule="evenodd"></path></svg>
          </button>
        </div>
      }
    </div>
    
  );
};

export default WebSite;
