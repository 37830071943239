import CICD from 'files/cicd';
import HrManagement from 'files/hrmanagement';
import Invoice from 'files/invoice';
import Meeting from 'files/meeting';
import Monitoring from 'files/monitoring';
import Project from 'files/project';
import Workspace from 'files/workspace';
import React from 'react';
import Marquee from 'react-fast-marquee';

const List = [
  {
    name: 'Project Management',
    text: 'In sprint organization, teams efficiently tackle tasks big and small by knowing what to do, why it matters, and how to get it done.',
    icon: <Project  width={50} height={50}/>
  },
  {
    name: 'Workspace',
    text: 'Kwapio simplifies teamwork by centralizing all project details visually in one digital workspace, boosting productivity for teams.',
    icon: <Workspace  width={50} height={50}/>
    
  },
  {
    name: 'Meetings',
    text: 'Kwapio streamlines meeting scheduling, enhances communication, and offers robust integration capabilities for efficient collaboration.',
    icon: <Meeting  width={50} height={50}/>
    
  },
  {
    name: 'Advanced HR Management',
    text: 'Utilize real-time data analytics in HR to promptly adjust strategies and measure their impact on employee engagement and organizational effectiveness..',
    icon: <HrManagement  width={50} height={50}/>
  
  },
  {
    name: 'Invoice Management',
    text: 'Send invoices to any contact and track payments upon receipt. We help businesses get paid faster and manage more efficiently.',
    icon: <Invoice  width={50} height={50}/>
   
  },
  {
    name: 'CI / CD Tool',
    text: 'The comprehensive developer platform to build and scale microservices, jobs and managed databases with a powerful UI, API & CLI.',
    icon: <CICD  width={50} height={50}/>
   
  },
  {
    name: 'Monitoring',
    text: 'Kwapio offers comprehensive monitoring solutions for systems and networks, ensuring optimal performance and detecting issues promptly.',
    icon: <Monitoring  width={50} height={50}/>
    
  }
];
// box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
const OnePlatform = () => {

  const Card = ({ icon, name, text}) => {
    return (
      <div className="border w-96 max-h-52 mb-6 rounded-lg p-6 shadow-shadow-01">
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            <div className="mr-2 h-16 w-16 rounded-full object-cover" >
              {icon}
            </div>
            <div>
              <h3 className="text-base font-semibold text-gray-900">{name}</h3>
            </div>
          </div>
        </div>
        <p className="my-6 text-sm font-normal text-gray-500">{text}</p>
      </div>
    );
  };

  return (
    <div className="pb-10 container mx-auto pt-5">
      <div className="px-4">
        <div className="lg:col-span-2 lg:col-start-1 lg:row-start-1 lg:mx-auto lg:grid lg:w-full  lg:grid-cols-2 lg:gap-x-8">
          <div className="lg:pr-4">
            <div className="lg:max-w-lg">
              <h2 className={` font-semibold text-3xl leading-7 text-kogreen-dark`} data-aos="fade-up" >One Platform</h2>
              <h1 className="mt-2 text-4xl font-extrabold tracking-tight text-gray-900 sm:text-4xl" data-aos="fade-up" >Many <span className>Solutions</span></h1>
              <p className="mt-6 text-lg leading-8 text-gray-700" data-aos="fade-up" >
                  Unify your teams efforts in a collaborative hub. Choose the ideal project view for your requirements and collaborate seamlessly from any location. Streamline and automate your teams tasks with our all-in-one solutions, thereby simplifying your workflow.
              </p>
            </div>
          </div>
        </div>
        <div data-aos="fade-up">
          <Marquee pauseOnHover={true} gradient={false} speed={40} className="overflow-hidden lg:pt-5" >
            {List.map((profile, index) => (
              <div key={index} className="flex-shrink-0 mx-4">
                <Card role={profile.role} name={profile.name} text={profile.text} icon={profile.icon} email={profile.email} linkedIn={profile.linkedIn} />
              </div>
            ))}
          </Marquee>
        </div>
        <div data-aos="fade-up">
          <Marquee pauseOnHover={true} direction="right" gradient={false} speed={40} className="overflow-hidden">
            {List.map((profile, index) => (
              <div key={index} className="flex-shrink-0 mx-4">
                <Card role={profile.role} name={profile.name} text={profile.text} icon={profile.icon} email={profile.email} linkedIn={profile.linkedIn} />
              </div>
            ))}
          </Marquee>
        </div>
      </div>
    </div>
  );
};

export default OnePlatform;
