import React, { useState } from 'react';
import styles from './price.module.scss';

const PricingSection = () => {
  const [billingType, setBillingType] = useState('monthly');

  const pricingOptions = {
    freemium: {
      monthly: '14',
      yearly: '14'
    },
    pro: {
      monthly: '$7',
      yearly: '$72'
    }
  };

  return (
    <div className={styles.wrapper}>
      <h2
        className={`${styles.titleColor} text-2xl text-gray-50 font-bold md:text-4xl text-center md:mb-5`}
        data-aos="fade-up"
        data-aos-duration="800"
      >
        Subscription
      </h2>

      <p className="max-w-3xl mx-auto mt-4 text-xl text-center">
        Detailed Pricing Information
      </p>
      <div className="flex justify-center items-center pt-5">
        <div className="relative self-center bg-slate-200 rounded-lg p-0.5 flex">
          <button
            type="button"
            className={`${
              billingType === 'monthly' ? 'bg text-gray-50' : ''
            } relative w-1/2 rounded-md py-2 text-sm font-medium whitespace-nowrap focus:outline-none sm:w-auto sm:px-8 border-slate-50`}
            onClick={() => setBillingType('monthly')}
          >
            Monthly billing
          </button>
          <button
            type="button"
            className={`${
              billingType === 'yearly' ? 'bg text-gray-50' : ''
            } ml-0.5 relative w-1/2 border rounded-md py-2 text-sm font-medium whitespace-nowrap focus:outline-none sm:w-auto sm:px-8 border-transparent`}
            onClick={() => setBillingType('yearly')}
          >
            Yearly billing
          </button>
        </div>
      </div>

      <div className="flex items-center justify-center my-16">
        <div className="space-y-12 lg:space-y-0 md:grid md:grid-cols-2 md:gap-x-8">
          <div
            className={`${styles.priceCard} lg:w-[25vw] relative p-8 border border-gray-200 rounded-2xl shadow-sm flex flex-col`}
          >
            <div className="flex-1 z-10">
              <h3 className="text-xl font-semibold">Freemium</h3>
              <p className="mt-4 flex items-baseline">
                <span className="text-5xl font-extrabold tracking-tight me-2">
                  {pricingOptions.freemium[billingType]}
                </span>
                <span className="ml-1 text-xl font-semibold">
                Days/trial package
                </span>
              </p>
            </div>
            <p
              className="bg-emerald-50 z-10 text-emerald-700 hover:bg-emerald-100 mt-8 block w-full py-3 px-6 cursor-pointer border-transparent rounded-md text-center font-medium"
              onClick={() =>
                (window.location.href =
                  'https://app.kwapio.com/account/create-account')
              }
            >
              Get Started
            </p>
          </div>
          {/* card 2 */}
          <div
            className={`${styles.priceCard} lg:w-[25vw] relative p-8 border rounded-2xl shadow-sm flex flex-col`}
          >
            <div className="flex-1 z-10">
              <h3 className="text-xl font-semibold">Pro</h3>
              <p className="absolute top-0 py-1.5 px-4 bg bg-emerald-500 text-white rounded-full text-xs font-semibold uppercase tracking-wide transform -translate-y-1/2">
                Most popular
              </p>
              <p className="mt-4 flex items-baseline">
                <span className="text-5xl font-extrabold tracking-tight me-2">
                  {pricingOptions.pro[billingType]}
                </span>
                <span className="ml-1 text-xl font-semibold">
                  per user/ {billingType === 'monthly' ? 'month' : 'year'}
                </span>
              </p>
            </div>
            <p
              className="bg bg-emerald-500 z-10 text-white hover:bg-emerald-600 mt-8 block w-full py-3 px-6 cursor-pointer border-transparent rounded-md text-center font-medium"
              onClick={() =>
                (window.location.href =
                  'https://app.kwapio.com/account/create-account')
              }
            >
              Get Started
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PricingSection;
